export const api = {

    login: '/api/auth',
    logout: '/api/logout',
    refreshToken: '/api/refresh',
    eventListG: '/api/guest/events',
    raceListG: '/api/guest/races',
    eventListNewG: '/api/guest/event_list',
    eventDetailG: '/api/guest/event',
    eventList: '/api/client/events',
    eventListNew: '/api/client/event_list',
    raceList: '/api/client/races',
    eventDetail: '/api/client/event',
    eventDetailOnly: '/api/client/event_detail',
    userData: '/ws/getUserData',
    odds: '/ws/getMarketData',
    oddsNew: '/ws/getMarketDataNew',
    exposure: '/api/client/exposes',
    accountStmt: '/api/client/report/statement',
    profitLoss: '/api/client/report/profit_loss',
    profitLossDetail: '/api/client/report/profit_loss_detail',
    betHistory: '/api/client/report/order_history',
    sportsBookBetHistory: '/api/client/sports-book/orders',
    eventTypeIds: '/api/client/event-type-id/list',
    fancyRunPosition: '/api/client/fancy_run_position',
    scoreData: '/ws/getScoreData',
    tvData: '/api/client/stream',
    ftLivetvData: 'https://abc.abc/api/fd9b8e37f4f6f2db0e96632e11679464dfe80ea2/Nstreamapi.php?chid=',
    placeBet: '/api/client/store_order',
    orderList: '/api/client/orders',
    stakesUpdate: '/api/client/update_stakes',
    casino1: '/api/client/casino_1',
    casino1G: '/api/guest/casino_1',
    casinoIG: '/api/guest/casino_i',
    //casinoIList: '/api/client/casino_i_list_new',
    casinoIList: '/api/client/casino_i_list',
    //casinoIListG: '/api/guest/casino_i',
    casinoIListG: '/api/guest/casino_i_list',
    casinoITbl: '/api/client/casino_i',
    casinoIntList: '/api/client/casino_int_list_new',
    casinoIntListG: '/api/guest/casino_int',
    casinoIntTbl: '/api/client/casino_int_new',
    changePass: '/api/change_password',
    myProfile: '/api/client/profile',
    rules: '/api/client/rules',
    menu: '/api/guest/menu',
    marketAnalysis: '/api/client/market-analysis',
    multiMarket: '/api/client/multi-market',
    saveMultiMarket: '/save',
    removeMultiMarket: '/remove',
    cashOut: '/api/client/cash-out',
    primiumCricket: '/api/client/get_ps_details',
    sportsBook: '/api/client/sports-book',
    accountStmtTrnsType: '/api/client/report/statement/trans-type',
    // tvData: '/api/get_tv2_url',
    marketAnalsis: '/api/client/market-analysis',
    bannerList: '/api/guest/sliders',
    deposit: '/api/client/get_deposit',
    withdraw: '/api/client/get_withdraw'
};
