import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  env = environment;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private titleService: Title,
    private meta: Meta
  ) {
    const window: any = this.document.defaultView;
    const host = window.location.hostname;
    this.setDynamicStyle(host);
    this.loadGoogleAnalytics();
  }

  async setDynamicStyle(host: any) {
    
    // const index = this.env.hosts.findIndex((e: any) => host.includes(e.domain));
    const index = this.env.hosts.findIndex((e: any) => (host === e.domain) || (host === 'www.'+e.domain));
    this.env.host = (index == -1) ? this.env.hosts[0] : this.env.hosts[index];
    if (index == -1) {
      this.env.host.domain = host;
    }
    // if (!localStorage.getItem(`${this.env.panel}theme`)) {
    //   localStorage.setItem(`${this.env.panel}theme`, this.env.host.theme);
    // }

    this.updateTitle(host);
    this.updateMeta();

    // this.titleService.setTitle(`Welcome to ${this.env.host.name == 'localhost' ? host : this.env.host.name}`);

    if (this.env.host.name != 'localhost') {
      this.env.baseUrl = `https://api.${this.env.host.domain}`;
      this.env.domain = this.env.host.domain;
    }
    // const styleEle = this.document.getElementById('theme') as HTMLElement
    // styleEle.setAttribute('href', this.env.isMobile ? this.env.host.mThemePath : this.env.host.dThemePath)
    const head = this.document.getElementsByTagName('head')[0];
    const stylePath = this.env.isMobile ? this.env.mobileStyles : this.env.desktopStyles
    stylePath.forEach(element => {
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.type = 'text/css';
      style.href = this.env.isMobile ? `assets/css/mobile/${element}` : `assets/css/desktop/${element}`;
      head.appendChild(style);
    });

    const themePath = this.env.host.themePath
    themePath.forEach(element => {
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${element}`;
      head.appendChild(style);
    });

  }

  updateTitle(host:any) {
    var title = `Welcome to ${this.env.host.name == 'localhost' ? host : this.env.host.name}`
    this.titleService.setTitle(`${this.env.host.siteTitle != '' ? this.env.host.siteTitle : title}`);
  }

  updateMeta() {
    this.meta.updateTag({ name: 'description', content: this.env.host.meta_dec });
    this.meta.updateTag({ name: 'keywords', content: this.env.host.meta_keyword });
  }

  loadGoogleAnalytics(){
    if (this.env.host.googleAnalytics.active && this.env.host.googleAnalytics.googleAnalyticsTrackingId != '' && this.env.host.googleAnalytics.googleSiteVerification != '') {

      let trackingID = this.env.host.googleAnalytics.googleAnalyticsTrackingId;
      let gaScript = document.createElement('script');
      gaScript.setAttribute('async', 'true');
      gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

      let gaScript2 = document.createElement('script');
      gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

      // Add the gaScript to the head section
      // append to head of document
      document.getElementsByTagName('head')[0].appendChild(gaScript); 
      document.getElementsByTagName('head')[0].appendChild(gaScript2); 
      // document.getElementsByTagName('head')[0].firstChild.appendChild(gaScript);
      // document.getElementsByTagName('head')[0].firstChild.appendChild(gaScript2);
      
      this.meta.updateTag({ name: 'google-site-verification', content: this.env.host.googleAnalytics.googleSiteVerification });
    }
  }

}
